import { theme as defaultTheme } from './theme.default';
import { GRID } from './tokens';

const theme = {
  ...defaultTheme,
  radii: {
    ...defaultTheme.radii,
    xs: `${GRID}px`,
    s: `${GRID * 2}px`,
    m: `${GRID * 4}px`,
    l: `${GRID * 6}px`,
    xl: `${GRID * 8}px`,
  },
};

export { theme };
