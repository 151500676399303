import { createStitches, PropertyValue, ScaleValue, defaultThemeMap } from '@stitches/react';

import { normalizeCSS } from './normalize';
import { mediaQueries } from './sparky.config';
import { globalStyles, iconSizes, themes, typography } from './themes';
import { Theme } from './types';

const mediaQueriesMax = {
  smMax: '(max-width: 374px)',
  mdMax: '(max-width: 767px)',
  lgMax: '(max-width: 1023px)',
  xlMax: '(max-width: 1439px)',
};

const mediaFeatures = {
  safeMotion: '(prefers-reduced-motion: no-preference)',
};

const theme = themes.default as Theme;

const stitches = createStitches({
  prefix: '',
  theme: theme,
  themeMap: {
    ...defaultThemeMap,
    display: 'display',
    outline: 'outlines',
    opacity: 'opacities',
  },
  media: { ...mediaQueries, ...mediaQueriesMax, ...mediaFeatures },
  utils: {
    paddingY: (value: ScaleValue<'space'> | 0) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    paddingX: (value: ScaleValue<'space'> | 0) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    marginY: (value: PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    marginX: (value: PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),

    typography: (value: `$${Typography}`) => {
      const key = value.toString().replace('$', '') as Typography;
      return typography[key];
    },

    iconography: (value: `$${Iconography}`) => {
      const key = value.toString().replace('$', '') as Iconography;

      return {
        width: iconSizes[key],
        height: iconSizes[key],
      };
    },

    dvh: (value: number) => {
      return {
        '@supports (height: 100dvh)': {
          height: `${value}dvh`,
        },
        '@supports (not(height: 100dvh))': {
          height: `${(window.innerHeight / 100) * value}px`,
        },
      };
    },
  },
});

const {
  styled,
  keyframes,
  css,
  globalCss: globalStitches,
  getCssText,
  theme: defaultTheme,
  createTheme,
  config,
} = stitches;
globalStitches({
  ...globalStyles,
  ...normalizeCSS,
})();

const themeClassNames = Object.entries(themes).reduce(
  (acc, [key, value]) => {
    acc[key] = createTheme(value).className;
    return acc;
  },
  {} as Record<string, string>,
);

// @ts-ignore TODO: We still need to decide on how to do this
const getToken = <T extends keyof Theme>(category: T, token: keyof Theme[T]) => theme[category][token];
const getTokens = (): Theme => theme;

type Typography = keyof typeof typography;
type Iconography = keyof typeof iconSizes;

export {
  config,
  createTheme,
  css,
  defaultTheme,
  getCssText,
  getToken,
  getTokens,
  globalStitches,
  keyframes,
  styled,
  themeClassNames,
};
