import { theme } from './theme.default';
import { theme as rebrandingTheme } from './theme.rebranding';
import { BASE_FONT_RELATIVE } from './tokens';
import { Fonts } from '../../types';
import { getFontFaces } from '../../util';

const fonts: Fonts = [
  ['Etelka', 300, 'etelkaLight.woff2', 'woff2'],
  ['Etelka', 500, 'etelkaMedium.woff2', 'woff2'],
  ['Etelka', 700, 'etelkaBold.woff2', 'woff2'],
  ['Etelka', 900, 'etelkaBlack.woff2', 'woff2'],
];

const bodyTypography = {
  bodyXL: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.BodyXL,
    lineHeight: theme.lineHeights['7'],
  },
  bodyL: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.BodyL,
    lineHeight: theme.lineHeights['7'],
  },
  bodyM: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.BodyM,
    lineHeight: theme.lineHeights['6'],
  },
  bodyS: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.BodyS,
    lineHeight: theme.lineHeights['5'],
  },
  bodyXS: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.BodyXS,
    lineHeight: theme.lineHeights['4'],
  },
  quoteM: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes['32'],
    lineHeight: theme.lineHeights['9'],
  },
  quoteS: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes['24'],
    lineHeight: theme.lineHeights['7'],
  },
} as const;

const headingTypography = {
  heading3xl: {
    letterSpacing: theme.letterSpacings.narrowM,
    fontSize: theme.fontSizes['3XL'],
    fontWeight: theme.fontWeights.headingRegular,
    lineHeight: theme.lineHeights['20'],
  },
  heading2xl: {
    letterSpacing: theme.letterSpacings.narrowM,
    fontSize: theme.fontSizes['2XL'],
    fontWeight: theme.fontWeights.headingRegular,
    lineHeight: theme.lineHeights['16'],
  },
  headingXl: {
    letterSpacing: theme.letterSpacings.narrowM,
    fontSize: theme.fontSizes.XL,
    fontWeight: theme.fontWeights.headingRegular,
    lineHeight: theme.lineHeights['12'],
  },
  headingL: {
    letterSpacing: theme.letterSpacings.narrowS,
    fontSize: theme.fontSizes.L,
    fontWeight: theme.fontWeights.headingRegular,
    lineHeight: theme.lineHeights['10'],
  },
  headingM: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.M,
    fontWeight: theme.fontWeights.headingRegular,
    lineHeight: theme.lineHeights['9'],
  },
  headingS: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.S,
    fontWeight: theme.fontWeights.headingRegular,
    lineHeight: theme.lineHeights['7'],
  },
  headingXs: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes.XS,
    fontWeight: theme.fontWeights.headingLight,
    lineHeight: theme.lineHeights['6'],
  },
  heading2xs: {
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes['2XS'],
    fontWeight: theme.fontWeights.headingLight,
    lineHeight: theme.lineHeights['6'],
  },
  heading3xs: {
    letterSpacing: theme.letterSpacings.wide,
    fontSize: theme.fontSizes['3XS'],
    fontWeight: theme.fontWeights.headingLight,
    lineHeight: theme.lineHeights['5'],
  },
} as const;

const iconSizes = {
  small: theme.sizes.small,
  medium: theme.sizes.medium,
  large: theme.sizes.large,
  extraLarge: theme.sizes.extraLarge,
  illustrationSmall: theme.sizes.illustrationSmall,
  illustrationMedium: theme.sizes.illustrationMedium,
  illustrationLarge: theme.sizes.illustrationLarge,
};

const typography = {
  ...bodyTypography,
  ...headingTypography,
};

const globalStyles = {
  '@font-face': getFontFaces(fonts),
  '@media (prefers-reduced-motion: no-preference)': {
    ':root': {
      'scroll-behavior': 'smooth',
    },
  },
  ':root': {
    fontSize: BASE_FONT_RELATIVE,
  },
  body: {
    color: theme.colors.textPrimary,
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.BodyM,
    fontWeight: theme.fontWeights.bodyRegular,
    lineHeight: theme.lineHeights['6'],
    margin: 0,

    '&#nl-eneco-selfservice': {
      display: 'block',
      backgroundColor: theme.colors.backgroundSecondary,
    },
  },

  'a, button': {
    '&:focus-visible': {
      outline: theme.outlines.outlineFocus,
    },
    '@supports not selector(:focus-visible)': {
      '&:focus': {
        outline: theme.outlines.outlineFocus,
      },
    },
  },

  'button, input, select, textarea': {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.bodyRegular,
  },

  '.seamly-placeholder': {
    zIndex: 2,
    position: 'relative',
    pointerEvents: 'all',
  },
};

const themes = {
  default: theme,
  rebranding: rebrandingTheme,
};

export { BASE_FONT_RELATIVE, globalStyles, iconSizes, theme, themes, typography };
