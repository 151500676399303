import * as eneco from '../../themes/eneco/tokens';
import * as oxxio from '../../themes/oxxio/tokens';
import * as woonenergie from '../../themes/woonenergie/tokens';

/**
 * Button specific tokens
 */

/** Colors - Format: [component][action][property][tone?][state?] */

/**
 * ENECO
 */
export const enecoButtonColors = {
  // Primary onLight Text
  buttonPrimaryOnLightDefaultText: eneco.textColors.textInverted,

  // Primary onLight Background
  buttonPrimaryOnLightDefaultBackground: eneco.backgroundColors.backgroundBrand,
  buttonPrimaryOnLightHoverBackground: `linear-gradient(0deg, rgba(48, 12, 56, 0.3), rgba(48, 12, 56, 0.3)), ${eneco.backgroundColors.backgroundBrand}`,
  buttonPrimaryOnLightFocusBackground: `linear-gradient(0deg, rgba(48, 12, 56, 0.3), rgba(48, 12, 56, 0.3)), ${eneco.backgroundColors.backgroundBrand}`,
  buttonPrimaryOnLightPressedBackground: `linear-gradient(0deg, rgba(48, 12, 56, 0.3), rgba(48, 12, 56, 0.3)), ${eneco.backgroundColors.backgroundBrand}`,

  // Primary onDark Text
  buttonPrimaryOnColorDefaultText: eneco.textColors.textPrimary,
  buttonPrimaryOnColorHoverText: eneco.textColors.textBrand,
  buttonPrimaryOnColorFocusText: eneco.textColors.textBrand,
  buttonPrimaryOnColorPressedText: eneco.textColors.textBrand,

  // Primary onDark Background
  buttonPrimaryOnColorDefaultBackground: eneco.neutralColors.neutralWhite,
  buttonPrimaryOnColorHoverBackground: eneco.neutralColors.neutralWhite,
  buttonPrimaryOnColorFocusBackground: eneco.neutralColors.neutralWhite,
  buttonPrimaryOnColorPressedBackground: eneco.neutralColors.neutralWhite,

  // Primary onDark Icon
  buttonPrimaryOnColorDefaultIcon: eneco.iconColors.iconPrimary,
  buttonPrimaryOnColorHoverIcon: eneco.iconColors.iconBrand,
  buttonPrimaryOnColorFocusIcon: eneco.iconColors.iconBrand,
  buttonPrimaryOnColorPressedIcon: eneco.iconColors.iconBrand,

  // Secondary
  buttonSecondaryDefaultText: eneco.textColors.textPrimary,
  buttonSecondaryDefaultBackground: eneco.backgroundColors.backgroundPrimary,
  buttonSecondaryDefaultBorderColor: eneco.borderColors.borderDividerMediumEmphasis,
  buttonSecondaryHoverText: eneco.textColors.textPrimary,
  buttonSecondaryHoverBackground: eneco.backgroundColors.backgroundSecondary,
  buttonSecondaryHoverBorderColor: eneco.borderColors.borderDividerMediumEmphasis,
  buttonSecondaryFocusBackground: eneco.backgroundColors.backgroundPrimary,
  buttonSecondaryFocusBorderColor: eneco.borderColors.borderFocus,
  buttonSecondaryPressedBackground: eneco.backgroundColors.backgroundTertiary,

  // Temporary rebrand definition. Should be removed when the button rebrand a/b test is finished.
  buttonPrimaryRebrandDefaultText: eneco.textColors.textInverted,
  buttonPrimaryRebrandDefaultBackground: '#007250',
  buttonPrimaryRebrandHoverBackground: '#00593F',
  buttonPrimaryRebrandPressedBackground: '#007250',

  // Loading
  buttonLoadingDefaultText: eneco.neutralColors.neutralWhite,
  buttonLoadingDefaultBackground: eneco.secondaryColors.blue700,
};

export const enecoButtonBorderWidths = {
  buttonSecondaryDefaultBorderWidth: eneco.borderWidths.m,
};

export const enecoButtonBorderRadii = {
  buttonBorderRadiusDefault: eneco.radii.s,
  // Temporary rebrand definition. Should be removed when the button rebrand a/b test is finished.
  buttonBorderRadiusRebrand: eneco.radii.m,
};

export const enecoButtonChevronDisplay = {
  buttonChevronDisplay: 'none',
};

/**
 * OXXIO
 */
export const oxxioButtonColors = {
  // Primary onLight Text
  buttonPrimaryOnLightDefaultText: oxxio.textColors.textPrimary,

  // Primary onLight Background
  buttonPrimaryOnLightDefaultBackground: oxxio.secondaryColors.yellow100,
  buttonPrimaryOnLightHoverBackground: oxxio.secondaryColors.yellow120,
  buttonPrimaryOnLightFocusBackground: oxxio.secondaryColors.yellow100,
  buttonPrimaryOnLightPressedBackground: oxxio.secondaryColors.yellow120,

  // Primary onDark Text
  buttonPrimaryOnColorDefaultText: oxxio.textColors.textPrimary,
  buttonPrimaryOnColorHoverText: oxxio.textColors.textPrimary,
  buttonPrimaryOnColorFocusText: oxxio.textColors.textPrimary,
  buttonPrimaryOnColorPressedText: oxxio.textColors.textPrimary,

  // Primary onDark Background
  buttonPrimaryOnColorDefaultBackground: oxxio.secondaryColors.yellow100,

  // Primary onDark Icon
  buttonPrimaryOnColorDefaultIcon: oxxio.iconColors.iconPrimary,
  buttonPrimaryOnColorHoverIcon: oxxio.iconColors.iconPrimary,
  buttonPrimaryOnColorFocusIcon: oxxio.iconColors.iconPrimary,
  buttonPrimaryOnColorPressedIcon: oxxio.iconColors.iconPrimary,

  // Secondary
  buttonSecondaryDefaultText: oxxio.textColors.textBrand,
  buttonSecondaryDefaultBackground: oxxio.neutralColors.neutralWhite,
  buttonSecondaryDefaultBorderColor: oxxio.brandColors.brandBlue,
  buttonSecondaryHoverText: oxxio.textColors.textPrimary,
  buttonSecondaryHoverBackground: oxxio.neutralColors.neutralWhite,
  buttonSecondaryHoverBorderColor: oxxio.neutralColors.black,
  buttonSecondaryFocusBackground: oxxio.neutralColors.neutralWhite,
  buttonSecondaryFocusBorderColor: oxxio.neutralColors.black,
  buttonSecondaryPressedBackground: oxxio.neutralColors.neutralWhite,

  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonPrimaryRebrandDefaultText: oxxio.textColors.textPrimary,
  buttonPrimaryRebrandDefaultBackground: oxxio.secondaryColors.yellow100,
  buttonPrimaryRebrandHoverBackground: oxxio.secondaryColors.yellow120,
  buttonPrimaryRebrandPressedBackground: oxxio.secondaryColors.yellow120,

  // Loading
  buttonLoadingDefaultText: oxxio.textColors.textPrimary,
  buttonLoadingDefaultBackground: oxxio.secondaryColors.yellow100,
};

export const oxxioButtonBorderWidths = {
  buttonSecondaryDefaultBorderWidth: oxxio.borderWidths.m,
};

export const oxxioButtonBorderRadii = {
  buttonBorderRadiusDefault: oxxio.radii.round,
  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonBorderRadiusRebrand: oxxio.radii.round,
};

export const oxxioButtonChevronDisplay = {
  buttonChevronDisplay: 'block',
};

/**
 * WOONENERGIE
 */
export const woonenergieButtonColors = {
  // Primary onLight Text
  buttonPrimaryOnLightDefaultText: woonenergie.textColors.textInverted,

  // Primary onLight Background
  buttonPrimaryOnLightDefaultBackground: woonenergie.brandGradients.brandGradient,
  buttonPrimaryOnLightHoverBackground: woonenergie.secondaryColors.blue200,
  buttonPrimaryOnLightFocusBackground: woonenergie.brandGradients.brandGradient,
  buttonPrimaryOnLightPressedBackground: woonenergie.secondaryColors.blue200,

  // Primary onDark Text
  buttonPrimaryOnColorDefaultText: woonenergie.textColors.textPrimary,
  buttonPrimaryOnColorHoverText: woonenergie.textColors.textBrand,
  buttonPrimaryOnColorFocusText: woonenergie.textColors.textBrand,
  buttonPrimaryOnColorPressedText: woonenergie.textColors.textBrand,

  // Primary onDark Background
  buttonPrimaryOnColorDefaultBackground: woonenergie.neutralColors.neutralWhite,

  // Primary onDark Icon
  buttonPrimaryOnColorDefaultIcon: woonenergie.iconColors.iconPrimary,
  buttonPrimaryOnColorHoverIcon: woonenergie.iconColors.iconBrand,
  buttonPrimaryOnColorFocusIcon: woonenergie.iconColors.iconBrand,
  buttonPrimaryOnColorPressedIcon: woonenergie.iconColors.iconBrand,

  // Secondary
  buttonSecondaryDefaultText: woonenergie.textColors.textPrimary,
  buttonSecondaryDefaultBackground: woonenergie.neutralColors.neutral200,
  buttonSecondaryDefaultBorderColor: woonenergie.neutralColors.neutral200,
  buttonSecondaryHoverText: woonenergie.textColors.textPrimary,
  buttonSecondaryHoverBackground: woonenergie.neutralColors.neutral300,
  buttonSecondaryHoverBorderColor: woonenergie.neutralColors.neutral300,
  buttonSecondaryFocusBackground: woonenergie.neutralColors.neutral300,
  buttonSecondaryFocusBorderColor: woonenergie.neutralColors.neutral300,
  buttonSecondaryPressedBackground: woonenergie.neutralColors.neutral300,

  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonPrimaryRebrandDefaultText: woonenergie.textColors.textInverted,
  buttonPrimaryRebrandDefaultBackground: woonenergie.brandGradients.brandGradient,
  buttonPrimaryRebrandHoverBackground: woonenergie.secondaryColors.blue200,
  buttonPrimaryRebrandPressedBackground: woonenergie.secondaryColors.blue200,

  // Loading
  buttonLoadingDefaultText: woonenergie.neutralColors.neutralWhite,
  buttonLoadingDefaultBackground: woonenergie.brandGradients.brandGradient,
};

export const woonenergieButtonBorderWidths = {
  buttonSecondaryDefaultBorderWidth: woonenergie.borderWidths.none,
};

export const woonenergieButtonBorderRadii = {
  buttonBorderRadiusDefault: woonenergie.radii.round,
  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonBorderRadiusRebrand: woonenergie.radii.round,
};

export const woonenergieButtonChevronDisplay = {
  buttonChevronDisplay: 'none',
};
