import {
  GRID,
  borderShadows,
  borderWidths,
  boxShadows,
  fontSizes,
  fontWeights,
  globalThemeColors,
  letterSpacings,
  lineHeights,
  opacities,
  outlines,
  radii,
  transitions,
} from './tokens';
import * as badgeTokens from '../../components/Badge/tokens';
import * as buttonTokens from '../../components/Button/tokens';
import * as headerTokens from '../../components/DesktopHeader/tokens';
import * as expandableTokens from '../../components/Expandable/tokens';
import * as messagebarTokens from '../../components/MessageBar/tokens';
import * as navlinkOnColorTokens from '../../components/NavLink/tokens';
import * as progressIndicatorTokens from '../../components/ProgressIndicator/tokens';
import * as ribbonTokens from '../../components/Ribbon/tokens';

/**
 * TODO: Add iconSizes to sizes scale maybe?
 * iconSmall, iconMedium, iconLarge
 */
const iconSizes = {
  small: '1.6rem',
  medium: '2.4rem',
  large: '3.2rem',
  extraLarge: '6.4rem',
  illustrationSmall: '4rem',
  illustrationMedium: '6rem',
  illustrationLarge: '8rem',
};

export const theme = {
  colors: {
    ...globalThemeColors,
    ...buttonTokens.enecoButtonColors,
    ...expandableTokens.enecoExpandableTokens,
    ...headerTokens.enecoHeaderColors,
    ...messagebarTokens.enecoMessagebarColors,
    ...navlinkOnColorTokens.enecoNavlinkOncolorColors,
    ...progressIndicatorTokens.enecoProgressIndicatorTokensColors,
    ...ribbonTokens.enecoRibbonColors,
    ...badgeTokens.enecoBadgeColors,
  },
  shadows: {
    ...boxShadows,
    ...borderShadows,
  },
  outlines: {
    ...outlines,
  },
  fonts: {
    body: 'Etelka, sans-serif',
    heading: 'Etelka, sans-serif',
  },
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  space: {
    0: '0px',
    1: 1 * GRID + 'px',
    2: 2 * GRID + 'px',
    3: 3 * GRID + 'px',
    4: 4 * GRID + 'px',
    5: 5 * GRID + 'px',
    6: 6 * GRID + 'px',
    7: 7 * GRID + 'px',
    8: 8 * GRID + 'px',
    10: 10 * GRID + 'px',
    12: 12 * GRID + 'px',
    16: 16 * GRID + 'px',
    24: 24 * GRID + 'px',
    32: 32 * GRID + 'px',
  },
  sizes: {
    1: '768px',
    2: '1024px',
    3: '1220px',
    4: '1432px',
    5: '1680px',
    buttonMinWidth: '80px',
    targetMinWidth: '44px',
    targetMinHeight: '44px',
    inputMinHeight: '48px',
    ...iconSizes,
  },
  borderWidths: {
    ...borderWidths,
    ...buttonTokens.enecoButtonBorderWidths,
    ...headerTokens.enecoHeaderDividerHeight,
  },
  radii: {
    ...radii,
    ...buttonTokens.enecoButtonBorderRadii,
  },
  transitions,
  opacities,
  display: {
    ...buttonTokens.enecoButtonChevronDisplay,
  },
} as const;
